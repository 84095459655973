.model {
  transition: all 5s ease !important;
  min-height: auto !important;
}

.model-close {
  display: none;
  visibility: hidden;
}

.model-open {
  display: block;
  visibility: visible;
}

.card-text {
  padding: 5px !important;
}

.card {
  min-height: auto !important;
}

.card:hover {
  cursor: pointer;
  background-color: rgb(232, 232, 232);
}

.nav-list {
  margin-inline: 10px !important;
}

.nav-link {
  text-decoration: none;
  color: black;
}

.MuiAppBar-root {
  box-shadow: none !important;
  border: 0px !important;
}

.MuiToolbar-root {
  border: none !important;
}

.MuiPaper-root {
  border: none !important;
}

thead .MuiTableCell-head {
  background-color: #4b26d1;
  color: white;
}

thead .MuiTableCell-root:first-child {
  border-top-left-radius: 10px;
  /* border-bottom-left-radius: 10px; */
}

thead .MuiTableCell-root:last-child {
  border-top-right-radius: 10px;
  /* border-bottom-right-radius: 10px; */
}

/* td button.MuiButton-root {
  padding: 1px 2px;
} */

.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: #4b26d1 !important;
  border: none !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.MuiTableRow-root.MuiTableRow-hover:hover > td {
  color: white !important;
}

.MuiTableRow-root.MuiTableRow-hover:hover > td a {
  color: white !important;
}

.order-card {
  border-radius: 10px !important;
  position: relative;
}

.card-bg-hover {
  position: absolute;
  top: 0;
  right: left;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: bottom left;
}

.MuiTableContainer-root {
  margin-inline: 0px !important;
}

.MuiDrawer-paperAnchorDockedLeft {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.MuiAppBar-root {
  box-shadow: none !important;
  border: 0px !important;
  background-color: white !important;
  color: #4b26d1 !important;
  border-bottom: 1px solid #4b26d1 !important;
}

.MuiToolbar-root {
  border: none !important;
}

em {
  margin: 2rem;
}

.order-card-1 {
  background: linear-gradient(98.86deg, #6baafc 0%, #305fec 100%);
}

.order-card-2 {
  background: linear-gradient(98.86deg, #ef5e7a 0%, #d35385 100%);
}

#nav-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
